import { useMatches } from '@remix-run/react'
import * as React from 'react'
import {
	type FormatWidth,
	type ImageBlock,
	type TextBlock,
} from '#types/graphql.page.js'
import { type Seo } from '#types/graphql.seomatic.js'

export const useSSRLayoutEffect =
	typeof window === 'undefined' ? () => {} : React.useLayoutEffect

export function widthToCol(w: FormatWidth) {
	if (!w) return 12
	const [num, div] = w.replace('full', '1/1').split('-')[1].split('/')
	return Number(num) / Number(div) || 12
}

export function seomaticMeta(seo: Seo) {
	if (!seo || seo === undefined) return [{}]
	const tags = Object.entries(seo.metaTagContainer)
		.filter(([key, val]) => !Array.isArray(val))
		.map(([key, val]) =>
			key.toLocaleLowerCase().includes('og')
				? {
						property: key.toLocaleLowerCase(),
						content: !Array.isArray(val) && val.content,
				  }
				: {
						name: key.toLocaleLowerCase(),
						content: !Array.isArray(val) && val.content,
				  },
		)

	return [
		{ title: seo.metaTitleContainer.title.title },
		...tags,
		{
			name: 'name',
			description: seo.metaJsonLdContainer.mainEntityOfPage.name,
		},
		{
			name: 'description',
			description: seo.metaJsonLdContainer.mainEntityOfPage.description,
		},
		// seo?.metaJsonLdContainer || {},
	]
}

export function useParentData(pathname: string) {
	let matches = useMatches()
	let parentMatch = matches.find(match => match.pathname === pathname)?.data
	if (!parentMatch) return null
	return parentMatch
}

export function useKeyPress(targetKey: string): boolean {
	// State for keeping track of whether key is pressed
	const [keyPressed, setKeyPressed] = React.useState(false)
	// If pressed key is our target key then set to true
	const downHandler = React.useCallback(
		({ key }: { key: string }): void => {
			if (key === targetKey) {
				setKeyPressed(true)
			}
		},
		[targetKey],
	)
	// If released key is our target key then set to false
	const upHandler = React.useCallback(
		({ key }: { key: string }): void => {
			if (key === targetKey) {
				setKeyPressed(false)
			}
		},
		[targetKey],
	)

	// Add event listeners
	React.useEffect(() => {
		window.addEventListener('keydown', downHandler)
		window.addEventListener('keyup', upHandler)
		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', downHandler)
			window.removeEventListener('keyup', upHandler)
		}
	}, [downHandler, upHandler]) // Empty array ensures that effect is only run on mount and unmount
	return keyPressed
}

export function removeTrailingSlash(s: string) {
	return s.length > 1 && s.endsWith('/') ? s.slice(0, -1) : s
}

export function removeStartingSlash(s: string) {
	return s.length > 1 && s.startsWith('/') ? s.slice(1) : s
}

export function getBlockColSize(block: TextBlock | ImageBlock) {
	if (!block.format) return 'md:col-span-1'
	const columnSize =
		widthToCol(
			(Array.isArray(block.format) && block?.format[0]?.columnSize) ||
				'full-width',
		) * 12
	return columnSize === 1
		? 'md:col-span-1'
		: columnSize === 2
		? 'md:col-span-2'
		: columnSize === 3
		? 'md:col-span-3'
		: columnSize === 4
		? 'md:col-span-4'
		: columnSize === 5
		? 'md:col-span-5'
		: columnSize === 6
		? 'md:col-span-6'
		: columnSize === 7
		? 'md:col-span-7'
		: columnSize === 8
		? 'md:col-span-8'
		: columnSize === 9
		? 'md:col-span-9'
		: columnSize === 10
		? 'md:col-span-10'
		: columnSize === 11
		? 'md:col-span-11'
		: 'md:col-span-12'
}
